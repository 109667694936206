(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$sessionStorage', '$localStorage', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Contact2ForUser', 'CustomerByUser', '$translate'];

    function SidebarController ($rootScope, $scope, $state, $stateParams, $sessionStorage, $localStorage, Auth, Principal, ProfileService, LoginService, Contact2ForUser, CustomerByUser, $translate) {
        var vm = this;

        vm.hideMenu = false;

        $scope.$on('hideMenu', function(newVal) {
            vm.hideMenu = true;
        });

        vm.showsidebar = true;
        if($state.current.name == "customerconfigure"){
            vm.showsidebar = false;
        }
        vm.isSidebarCollapsed = false;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleSidebar = toggleSidebar;
        vm.collapseSidebar = collapseSidebar;
        vm.$state = $state;

        vm.tabs = {
            manage: {
                show: false
            },
            billing: {
                show: false
            },
            submit: {
                show: false
            }
        }

        vm.getDbaName = function() {
        	return $sessionStorage.customer ? $sessionStorage.customer.dbaName : $localStorage.customer ? $localStorage.customer.dbaName : '';
        }

        getCustomerData();

        

        function setTabsVisibility(contact, language) {
            var current_user = JSON.parse(localStorage.getItem('jhi-oldUserLogin'))
            if(!current_user) return
            current_user = current_user.replace(/\"/g, "")
            let country_id = 0;

            if(vm.customerData.country && vm.customerData.country.id) {
                country_id = vm.customerData.country.id;
            }
            
            //Country is JAPAN
            if(($localStorage.isDaichi && $localStorage.isDaichi === 'True') || (country_id === 3 && current_user === 'daichi@anresco.com')) {
                vm.tabs.submit.show = true;
                vm.tabs.manage.show = true;
                vm.tabs.billing.show = true;
            } else if(country_id !== 3) {
                vm.tabs.submit.show = true;
                vm.tabs.manage.show = true;
                vm.tabs.billing.show = true;
            }
        }

        function getCustomerData() {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                    vm.customerData = data;
                    getCurrentContact();
            }
            function onError(error) {
                console.log(error);
            }
        }


        function getCurrentContact() {
            var contact_customer_index = null;

            Contact2ForUser.get({}, onSuccessCustomer, onErrorCustomer);
            function onSuccessCustomer(data, headers) {
                vm.contact = data;

                if(data.contactCustomers && vm.customerData.id) {
                    contact_customer_index = findWithAttr(data.contactCustomers, 'customer_id', vm.customerData.id);
                    if(contact_customer_index != -1) {
                        vm.contact.receive_coa = data.contactCustomers[contact_customer_index].receive_coa;
                        vm.contact.receive_invoice = data.contactCustomers[contact_customer_index].receive_invoice;
                        setTabsVisibility(vm.contact, $localStorage.language)
                        let it;
                        it = setInterval(()=>{
                            let user = localStorage.getItem('jhi-oldUserLogin')
                            // console.log("user",user)
                            if(user) {
                                setTabsVisibility(vm.contact, $localStorage.language)
                                clearInterval(it)
                            }
                        },10)
                    }
                }
            }
            function onErrorCustomer(error) {
                console.log(error);
            }
        }

        function findWithAttr(array, attr, value) {
            for(var i = 0; i < array.length; i += 1) {
                if(array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        }

        function login() {
            collapseSidebar();
            $state.go('login');
        }

        function logout() {
            collapseSidebar();
            Auth.logout();
            $state.go('login');
        }

        function toggleSidebar() {
            vm.isSidebarCollapsed = !vm.isSidebarCollapsed;
        }

        function collapseSidebar() {
            vm.isSidebarCollapsed = true;
        }

        vm.japanese_portal = $localStorage.language == 'Japanese' ? true : false;
    }
})();
