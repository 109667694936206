(function () {
  "use strict";

  angular
    .module("anrescowebApp")
    .factory("AuthServerProvider", AuthServerProvider);

  AuthServerProvider.$inject = [
    "$http",
    "$localStorage",
    "$sessionStorage",
    "$q",
  ];

  function AuthServerProvider($http, $localStorage, $sessionStorage, $q) {
    var service = {
      getToken: getToken,
      login: login,
      backdoor: backdoor,
      loginWithToken: loginWithToken,
      storeAuthenticationToken: storeAuthenticationToken,
      logout: logout,
    };

    return service;

    function getToken() {
      return (
        $localStorage.authenticationToken || $sessionStorage.authenticationToken
      );
    }

    function login(credentials) {
      var data = {
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe,
      };
      return $http.post("api/authenticate", data).success(authenticateSuccess);

      function authenticateSuccess(data, status, headers) {
        var customerId = headers("Customer-ID");
        var contactId = headers("Contact-ID");
        var contactEmail = headers("Contact-Email");
        $localStorage.customerId = customerId;
        $localStorage.contactId = contactId;
        $localStorage.contactEmail = contactEmail;
        $localStorage.language = data.language ? data.language : "English";

        var bearerToken = headers("Authorization");
        if (
          angular.isDefined(bearerToken) &&
          bearerToken.slice(0, 7) === "Bearer "
        ) {
          var jwt = bearerToken.slice(7, bearerToken.length);
          service.storeAuthenticationToken(jwt, credentials.rememberMe);
          return jwt;
        }
      }
    }

    function backdoor(credentials) {
      var data = {
        username: credentials.username,
        password: credentials.password,
        backdoor_username: credentials.backdoor_username,
        rememberMe: credentials.rememberMe,
      };
      return $http.post("api/backdoor", data).success(authenticateSuccess);

      function authenticateSuccess(data, status, headers) {
        console.log(
          "Values returned in backdoor",
          data.language,
          "And \n",
          data
        );
        var customerId = headers("Customer-ID");
        $localStorage.customerId = customerId;
        $localStorage.backdoor_user = true;
        $localStorage.language = data.language ? data.language : "English";

        var isDaichiHeader = headers("Is-Daichi");
        console.log("Is-Daichi header:", isDaichiHeader);
        if (isDaichiHeader === "True") {
          console.log("Setting isDaichi in localStorage");
          $localStorage.isDaichi = "True";
        } else {
          console.log("Not setting isDaichi in localStorage");
        }

        var bearerToken = headers("Authorization");
        if (
          angular.isDefined(bearerToken) &&
          bearerToken.slice(0, 7) === "Bearer "
        ) {
          var jwt = bearerToken.slice(7, bearerToken.length);
          service.storeAuthenticationToken(jwt, credentials.rememberMe);
          return jwt;
        }
      }
    }

    function loginWithToken(jwt, rememberMe) {
      var deferred = $q.defer();

      if (angular.isDefined(jwt)) {
        this.storeAuthenticationToken(jwt, rememberMe);
        deferred.resolve(jwt);
      } else {
        deferred.reject();
      }

      return deferred.promise;
    }

    function storeAuthenticationToken(jwt, rememberMe) {
      if (rememberMe) {
        $localStorage.authenticationToken = jwt;
      } else {
        $sessionStorage.authenticationToken = jwt;
      }
    }

    function logout() {
      delete $localStorage.authenticationToken;
      delete $sessionStorage.authenticationToken;
      delete $localStorage.customer;
      delete $sessionStorage.customer;
    }
  }
})();
